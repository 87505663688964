@use '@glise/styles/globals' as *;
.Toast {
  display: flex;

  align-items: center;
  justify-content: space-between;
  gap: $primary-gap;
  border-radius: $primary-border-radius;
  width: 25rem;
  min-width: 4.375rem;
  min-height: 4.375rem;

  .ButtonWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .Button {
      width: 100%;
      height: 100%;
      text-transform: capitalize;
      border-radius: 0.5rem;
      border-left: none !important;
      border-top: none !important;
      border-bottom: none !important;
      border-bottom-left-radius: 0.1px !important;
      border-top-left-radius: 0.1px !important;

      &.OnlyButton {
        &:hover {
          border-bottom-left-radius: 0.1px !important;
          border-top-left-radius: 0.1px !important;
        }

        width: 100%;
        height: 100%;
        padding: 1.4375rem;
      }
    }
  }
}

.InfoWrapper {
  display: flex;
  gap: $primary-gap;
  padding: 0.5rem 1rem;

  .purple {
    svg {
      color: $darkThemeBasePurple !important;
    }
  }

  .info {
    svg {
      color: white;
    }
  }

  .MessageWrapper {
    gap: 0.75rem;

    .Label {
      font-size: $font-smaller;
      line-height: 1.125rem;
      margin: 0;

      &.purple {
        color: $darkThemeBasePurple !important;
      }
    }

    .Message {
      font-size: $font-smaller;
      font-weight: 400;
      margin: 0;
      line-height: 1.125rem;
    }
  }
}

[data-theme='light'] {
  .Toast {
    background: $lightThemeBaseWhiteLight;
    box-shadow: $toast-box-shadow;

    .ButtonWrapper {
      .Button {
        border-left: 0.0625rem solid $lightThemeBaseWhite;
        color: $lightThemeBaseGrayDarkest;

        &:hover {
          background-color: $lightThemeBaseWhiteLightest;
        }

        &:active {
          background-color: $lightThemeBaseWhiteLightest;
        }

        &:first-child {
          border-bottom: 0.0625rem solid $lightThemeBaseWhite;
        }

        &.ButtonError {
          color: $lightThemeBaseRed;
        }
      }
    }

    .InfoWrapper {
      .MessageWrapper {
        .Message {
          color: $lightThemeBaseGrayDarkest;
        }
      }
    }
  }
}

[data-theme='dark'] {
  .Toast {
    background: $darkThemeBaseBlack;
    box-shadow: $toast-box-shadow;
    border: 1px solid $darkThemeBaseDarkDarker !important;

    .ButtonWrapper {
      .Button {
        border-left: 0.0625rem solid $darkThemeBaseDark;
        color: $darkThemeBaseWhiteDarkest;
        border-left: 1px solid $darkThemeBaseDarkDarker !important;

        &:hover {
          background-color: $darkThemeBaseBlackDarker;
        }

        &:active {
          background-color: $darkThemeBaseBlackDarker;
        }

        &:first-child {
          border-bottom: 0.0625rem solid $darkThemeBaseDark;
        }

        &.ButtonError {
          color: $darkThemeBaseRedLight;
        }
      }
    }

    .InfoWrapper {
      .MessageWrapper {
        .Label {
          color: $darkThemeBaseWhiteDarker;
        }

        .Message {
          color: $darkThemeBaseWhiteDarkest;
        }
      }
    }
  }
}
