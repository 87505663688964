@use '@glise/styles/globals' as *;
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
                  $darkThemeBaseBlackDarker,
                  0.88
  ); // Semi-transparent background
  display: flex;
  justify-content: center; // Center horizontally
  align-items: center; // Center vertically
  z-index: 1000; // Ensure it's above other elements
}

.CookiesConsentPopup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 16px;
  background: #0d0d0f;
  padding: 1.5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: fit-content;
  color: $darkThemeBaseWhiteDarker;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @include md {
    right: 0;
    left: 0;
    margin: auto;
    max-width: 100%;
  }

  .Title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $darkThemeBaseWhiteDarker;

    h3 {
      font-size: 24px;
      margin-bottom: 0;
      font-weight: 400;
    }
  }

  .AgreeText,
  a {
    color: $lightThemeBaseGrayLight;
    font-size: 16px;
    font-weight: 300;

    a {
      transition: 0.25s ease-in-out;
      color: $darkThemeBasePurple;
    }
  }

  .ActionButtonsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
  }
}
