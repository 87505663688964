// Dark Theme Colors

$darkThemeBaseBlackDarkest: rgba(24, 24, 26, 0.7);
$darkThemeBaseBlackDarker: #18181a;
$darkThemeBaseBlack: #1b1b1f;
$darkThemeBaseBlackLight: #1f1f23;
$darkThemeBaseBlackLighter: rgba(255, 255, 255, 0);

$darkThemeBaseDarkDarkest: #27282d;
$darkThemeBaseDarkDarker: #2e3035;
$darkThemeBaseDark: #35373c;
$darkThemeBaseDarkLight: #3c3f44;
$darkThemeBaseDarkLightest: #464b50;

$darkThemeBaseWhiteDarkest: #adb1b8;
$darkThemeBaseWhiteDarker: #edeef0;
$darkThemeBaseWhite: #f9fafb;
$darkThemeBaseWhiteLight: #f3f4f6;
$darkThemeBaseWhiteLightest: #fff;

$darkThemeBaseGrayDarkest: #030712;
$darkThemeBaseGrayDarker: #4b5563;
$darkThemeBaseGray: #696e77;
$darkThemeBaseGrayLightest: #ccc;

$darkThemeBaseBlueDarkest: #172554;
$darkThemeBaseBlueDarker: #1e3a8a;
$darkThemeBaseBlue: #221fa0;
$darkThemeBaseBlueLight: #2522a9;
$darkThemeBaseBlueLightest: #3b82f6;

$darkThemeBaseAzure: #61dafb;

$darkThemeBasePurple: #6b68ff;

$darkThemeBaseRedDarkest: #be123c;
$darkThemeBaseRedDarker: #e11d48;
$darkThemeBaseRed: #f43f5e;
$darkThemeBaseRedLight: #fb7185;

$purpleBlackGradient: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0.16) 0%,
                $darkThemeBaseBlackLighter 100%
),
$darkThemeBasePurple;

$redGrayGradient: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0.16) 0%,
                $darkThemeBaseBlackLighter 100%
),
$darkThemeBaseRedDarker;

// Dark Theme Tags
$darkThemeTagNeutralBg: $darkThemeBaseDarkDarker;
$darkThemeTagNeutralBgHover: $darkThemeBaseDark;
$darkThemeTagNeutralText: $darkThemeBaseWhiteDarkest;
$darkThemeTagNeutralIcon: #7d828a;
$darkThemeTagNeutralBorder: $darkThemeBaseDarkLight;
$darkThemeTagPurpleBg: #2e1064;
$darkThemeTagPurpleText: #a78bfa;
$darkThemeTagPurpleIcon: #8b5cf6;
$darkThemeTagPurple: #4c1d95;
$darkThemeTagBlueBg: $darkThemeBaseBlueDarkest;
$darkThemeTagBlueBgHover: #1e2a8a;
$darkThemeTagBlueText: #60a5fa;
$darkThemeTagBlueIcon: $darkThemeBaseBlueLightest;
$darkThemeTagBlueBorder: $darkThemeBaseBlueDarker;
$darkThemeTagGreenBg: #022c22;
$darkThemeTagGreenText: #34d399;
$darkThemeTagGreenIcon: #10b981;
$darkThemeTagGreen: #064e3b;
$darkThemeTagOrangeBg: #451a03;
$darkThemeTagOrangeText: #fbbf24;
$darkThemeTagOrangeIcon: #f59e0b;
$darkThemeTagOrange: #78350f;
$darkThemeTagRedBg: #4c0519;
$darkThemeTagRed: #881337;
$darkThemeTagRedText: $darkThemeBaseRedLight;
$darkThemeTagRedIcon: $darkThemeBaseRed;

// Light Theme Colors

$lightThemeBaseWhiteDarkest: #f1f3f5;
$lightThemeBaseWhiteDarker: #f3f4f6;
$lightThemeBaseWhite: #e5e7eb;
$lightThemeBaseWhiteLight: #fff;
$lightThemeBaseWhiteLightest: #f9fafb;

$lightThemeBaseGrayDarkest: #4b5563;
$lightThemeBaseGrayDarker: #9ca3af;
$lightThemeBaseGray: #dbeafe;
$lightThemeBaseGrayLight: #d1d5db;
$lightThemeBaseGrayLightest: #eff6ff;

$lightThemeBaseDarkDarkest: #030712;
$lightThemeBaseDarkDarker: rgba(3, 7, 18, 0.4);
$lightThemeBaseDarkLight: rgba(255, 255, 255, 0);
$lightThemeBaseDarkLightest: rgba(3, 7, 18, 0);

$lightThemeBaseRedDarker: #be123c;
$lightThemeBaseRed: #e11d48;

$lightThemeBaseBlueDarkest: #221fa0;
$lightThemeBaseBlueDarker: #2522a9;
$lightThemeBaseBlueLightest: #2563eb;

$lightThemeBaseAzure: #61dafb;

$lightThemeBasePurple: #6b68ff;
$lightThemeBasePurpleLighter: #282743;

$lightThemePurpleGrayGradient: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0.16) 0%,
                $lightThemeBaseDarkLight 100%
),
$lightThemeBasePurple;

$lightThemeRedGrayGradient: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0.16) 0%,
                $lightThemeBaseDarkLight 100%
),
$lightThemeBaseRed;

// White Theme Tags
$lightThemeTagNeutralBg: $lightThemeBaseWhiteDarker;
$lightThemeTagNeutralBgHover: $lightThemeBaseWhite;
$lightThemeTagNeutralText: $lightThemeBaseGrayDarkest;
$lightThemeTagNeutralIcon: #6b7280;
$lightThemeTagNeutralBorder: $lightThemeBaseWhite;
$lightThemeTagPurpleBg: #ede9fe;
$lightThemeTagPurple: #ddd6fe;
$lightThemeTagPurpleText: #6d28d9;
$lightThemeTagPurpleIcon: #7c3aed;
$lightThemeTagBlueBg: $lightThemeBaseGray;
$lightThemeTagBlueText: #1d4ed8;
$lightThemeTagBlueIcon: #2563eb;
$lightThemeTagBlue: #bfdbfe;
$lightThemeTagGreenBg: #d1fae5;
$lightThemeTagGreen: #a7f3d0;
$lightThemeTagGreenText: #047857;
$lightThemeTagGreenIcon: #059669;
$lightThemeTagOrangeBg: #fef4c7;
$lightThemeTagOrangeText: #b45309;
$lightThemeTagOrangeIcon: #d97706;
$lightThemeTagOrange: #fde68a;
$lightThemeTagRedBg: #ffe4e6;
$lightThemeTagRed: #fecdd3;
$lightThemeTagRedText: $lightThemeBaseRedDarker;
$lightThemeTagRedIcon: $lightThemeBaseRed;
