@use '@glise/styles/globals' as *;
.LayoutBackground {
  background-size: 100%, 100vh;
  background-repeat: no-repeat;
  width: 100vw;
  max-width: 100%;
  background-position: top;

  .Layout {
    padding-top: 7rem;
    display: flex;
    flex-direction: column;
    gap: 10rem;
  }
}
