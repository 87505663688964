// Small tablets and large smartphones (landscape view)
$screen-sm: 640;

// Small tablets (portrait view)
$screen-md: 768px;

// Tablets and small desktops
$screen-lg: 1024px;

// Large tablets and desktops
$screen-xl: 1280px;

// Extra large desktops
$screen-xxl: 1440px;

// Mixins.
@mixin sm {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: #{$screen-xl}) {
    @content;
  }
}

// Extra large desktop
@mixin xxl {
  @media (max-width: #{$screen-xl}) {
    @content;
  }
}
