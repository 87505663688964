@use '@glise/styles/globals' as *;
button.Button,
a.Button {
  border-radius: 0.4rem;
  text-transform: inherit;
  min-width: fit-content;
  font-weight: 400;
  border-width: 1px;

  &:hover {
    background: none;
  }

  &.sm {
    font-size: $button-fontsize-md;
    padding: $button-padding-sm;

    height: 1.875rem;
    min-width: fit-content;
  }

  &.md {
    font-size: $button-fontsize-md;
    padding: $button-padding-md;

    height: 30px;
  }

  &.lg {
    font-size: $button-fontsize-lg;
    padding: $button-padding-lg;

    height: 40px;
  }

  &.xl {
    font-size: $button-fontsize-xl;
    padding: $button-padding-xl;

    height: 2.875rem;
  }

  &.blue {
    svg {
      //filter: brightness(0) invert(1);
    }
  }

  &.red {
    svg {
      //filter: brightness(0) invert(1);
    }
  }

  &.white {
    svg {
      //filter: brightness(0) invert(1);
    }
  }
}

[data-theme='light'] {
  button.Button,
  a.Button {
    color: $lightThemeBaseWhiteLight;

    &.blue {
      background: $lightThemePurpleGrayGradient;
      color: $lightThemeBaseWhiteLight;

      svg {
        //filter: brightness(0) invert(1);
      }

      &:hover {
        background: $lightThemeBaseBlueDarker;
      }

      &:active {
        background: $lightThemeBaseBlueDarkest;
      }
    }

    &.white {
      background: $lightThemeBaseWhite;
      color: $lightThemeBaseDarkDarkest;

      svg {
        //filter: brightness(0) invert(1);
      }

      &:hover {
        background: $lightThemeBaseWhite;
      }

      &:active {
        background: $lightThemeBaseWhite;
      }
    }

    &.red {
      color: $lightThemeBaseWhiteLight;
      border: 1px solid $lightThemeRedGrayGradient;
      background: $lightThemeRedGrayGradient;

      svg {
        //filter: brightness(0) invert(1);
      }

      &:hover {
        background: $lightThemeBaseRed;
      }

      &:active {
        background: $lightThemeBaseRedDarker;
      }
    }

    &.gray {
      outline: 1px solid $lightThemeBaseWhite;
      background: $lightThemeBaseWhiteLight;
      border: 1px solid $lightThemeBaseWhiteLight;
      color: $lightThemeBaseDarkDarkest;

      &:hover {
        background: $lightThemeBaseWhiteLightest;
      }

      &:active {
        background: $lightThemeBaseWhiteLightest;
      }
    }

    &.transparent {
      border: 1px solid $lightThemeBaseDarkLightest;
      background: $lightThemeBaseDarkLight;
      color: $lightThemeBaseDarkDarkest;

      &:hover {
        border: 1px solid $lightThemeBaseDarkLightest;
        background: $lightThemeBaseWhiteLightest;
      }

      &:active {
        border: 1px solid $lightThemeBaseDarkLightest;
        background: $lightThemeBaseWhiteLightest;
      }
    }
  }
}

[data-theme='dark'] {
  button.Button,
  a.Button {
    color: $darkThemeBaseWhiteLightest;

    &.blue {
      background: $purpleBlackGradient;
      color: $darkThemeBaseWhiteLightest;

      a {
        color: $darkThemeBaseWhiteLightest;
      }

      svg {
        //filter: brightness(0) invert(1);
      }

      &:hover {
        background: $darkThemeBaseBlueLight;
      }

      &:active {
        background: $darkThemeBaseBlue;
      }
    }

    &.green {
      background: $darkThemeTagGreenIcon;
      color: $darkThemeBaseWhiteLightest;

      a {
        color: $darkThemeBaseWhiteLightest;
      }

      svg {
        //filter: brightness(0) invert(1);
      }

      &:hover {
        background: $darkThemeTagGreen;
      }

      &:active {
        background: $darkThemeTagGreen;
      }
    }

    &.white {
      background: $darkThemeBaseWhite;
      color: $darkThemeBaseGrayDarkest;

      a {
        color: $darkThemeBaseGrayDarkest;
      }

      svg {
        //filter: brightness(0) invert(1);
      }

      &:hover {
        background: rgba($darkThemeBaseWhite, 0.8);
      }

      &:active {
        background: $darkThemeBaseWhite;
      }
    }

    &.red {
      color: $darkThemeBaseWhiteLightest;
      border: 1px solid $redGrayGradient;
      background: $redGrayGradient;

      a {
        color: $darkThemeBaseWhiteLightest;
      }

      svg {
        //filter: brightness(0) invert(1);
      }

      &:hover {
        background: $darkThemeBaseRedDarker;
      }

      &:active {
        background: $darkThemeBaseRedDarkest;
      }
    }

    &.gray {
      background: $darkThemeBaseDarkDarkest;
      border: 1px solid $darkThemeBaseDarkDarkest;
      color: $darkThemeBaseWhiteLightest;

      a {
        color: $darkThemeBaseWhiteLightest;
      }

      svg {
        //filter: brightness(0) invert(1);
      }

      &:hover {
        border-radius: 6px;
        background: $darkThemeBaseDark;
      }

      &:active {
        background: $darkThemeBaseDarkLight;
      }
    }

    &.transparent {
      border: 1px solid $lightThemeBaseDarkLightest;
      background: $darkThemeBaseBlackLighter;
      color: $darkThemeBaseWhiteDarker;

      a {
        color: $darkThemeBaseWhiteDarker;
      }

      &:hover {
        border: 1px solid $lightThemeBaseDarkLightest;
        background: $darkThemeBaseWhite;
        color: $darkThemeBaseBlack;
      }

      &:active {
        border: 1px solid $lightThemeBaseDarkLightest;
        background: $darkThemeBaseWhiteLight;
        color: $darkThemeBaseBlack;
      }
    }

    &.transparent-gray-border {
      border: 1px solid transparent;
      background: transparent;
      color: $darkThemeBaseWhiteDarker;

      a {
        color: $darkThemeBaseWhiteDarker;
      }

      &:hover {
        border: 1px solid $darkThemeBaseDarkDarkest;
        background: $darkThemeBaseDarkDarkest;
      }

      &:active {
        border: 1px solid $darkThemeBaseDarkDarkest;
        background: $darkThemeBaseDarkDarkest;
      }
    }

    &:disabled {
      cursor: not-allowed;
      //border: 1px solid $darkThemeBaseDarkDarkest;
      background: $darkThemeBaseDarkDarkest !important;
      color: $darkThemeBaseDarkLight;

      a {
        color: $darkThemeBaseDarkLight;
      }

      svg {
        color: $darkThemeBaseDarkLight;
      }

      svg,
      path {
        //filter: none;
      }
    }
  }
}
