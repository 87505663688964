@use '@glise/styles/globals' as *;
.FooterContainer {
  display: flex;
  padding: 4rem 0 2rem 0;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  background: #0d0d0f;

  @include md {
    padding: 2.5rem 0 1.5rem 0;
    gap: 2rem;
  }

  .FooterWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 4rem;
    width: 100%;

    @include md {
      flex-direction: column;
      gap: 2rem;
    }

    .LinksContainer {
      display: flex;
      flex-direction: row;

      @include md {
        flex-direction: column;
      }

      .LinksWrapper {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        @include md {
          flex-direction: column;
        }

        .Logo {
          width: min-content;

          &:hover {
            svg {
              path {
                fill: $darkThemeBasePurple;
              }
            }
          }

          @include md {
            margin: 0 auto;
          }
        }

        .AllLinksContainer {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;

          .LinksRow {
            display: flex;
            flex-direction: row;
            gap: 1.5rem;

            @include md {
              flex-direction: column;
            }

            a {
              color: $darkThemeBaseWhiteLightest;
              text-align: center;
              font-size: 0.875rem;

              &:hover {
                color: $darkThemeBasePurple;
              }
            }
          }

          .SocialNetworks {
            display: flex;
            align-items: center;
            gap: 1.5rem;

            @include md {
              justify-content: center;
            }

            a {
              text-align: center;
              color: $darkThemeBaseWhiteDarker;

              &:hover {
                color: $darkThemeBasePurple;
              }
            }
          }
        }
      }
    }
  }
}

.InputContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;

  .FooterPopup {
    position: absolute;
    bottom: -50%;
    right: 3rem;

    @include md {
      left: 0;
      right: 0;
    }
  }

  .InputTitle {
    color: $darkThemeBaseWhiteDarker;

    font-size: 1.125rem;
    line-height: 1.25rem;

    @include md {
      text-align: center;
    }
  }

  .InputRow {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: flex-end;
    gap: 1rem;

    @include md {
      //flex-direction: column;
      //align-items: center;
      justify-content: center;
    }

    .InputWrapper {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      .InputSpan {
        color: $darkThemeBaseWhiteDarkest;

        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
      }
    }
  }

  .InputPolicy {
    color: $darkThemeBaseWhiteDarker;

    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    display: flex;
    flex-direction: row;
    gap: 0.25rem;

    @include md {
      white-space: nowrap;
      font-weight: 300;
      justify-content: center;
    }

    a {
      color: $darkThemeBaseWhiteDarker;
      text-decoration-line: underline !important;

      &:hover {
        color: $darkThemeBasePurple;
      }
    }
  }
}

.FooterInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  color: $darkThemeBaseWhiteDarker;

  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  border-top: 1px solid $darkThemeBaseDarkDarker;
  padding-top: 1.5rem;

  @include md {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Copyright {
    @include md {
      padding-top: 1rem;
      text-align: center;
    }
  }

  .FooterInfoWrapper {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;

    > div {
      cursor: pointer;
    }

    @include md {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    a,
    div {
      color: $darkThemeBaseWhiteDarker;

      font-size: 0.875rem;
      font-weight: 300;
      line-height: 1.25rem;

      &:hover {
        color: $darkThemeBasePurple;
      }
    }
  }
}
