@use '@glise/styles/globals' as *;
.TrialContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.625rem;
  width: 100%;
  position: relative;
  height: 100vh;
  background-size: 100%, 100vh;
  background-repeat: no-repeat;
  background-position: bottom;

  @include lg {
    height: 60vh;
  }

  .TrialTextContainer {
    z-index: 5;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .BigText {
      text-align: center;
      background: linear-gradient(170deg, #fff 19.13%, #5c44f2 125.28%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @include colorfulTextBig;

      & {
        font-size: 120px;
        line-height: 145px;
      }

      @include md {
        font-size: 3rem;
      }
    }

    .SmallText {
      color: $darkThemeBaseWhiteDarker;
      text-align: center;
      font-weight: 600;
      font-size: 20px;

      @include md {
        font-size: 16px;
        padding: 0 1rem;
      }
    }

    .Button {
      color: $darkThemeBasePurple !important;

      svg {
      }
    }
  }
}
