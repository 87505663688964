@use '@glise/styles/globals' as *;
.CheckboxContainer {
  //width: min-content;
  margin-top: 2px;
  margin-bottom: 2px;

  &.FullWidth {
    width: 100%;
  }

  .CheckboxWrapper {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    margin-left: 2px;

    input,
    label {
      cursor: pointer;
    }

    .Checkbox {
      flex-shrink: 0;
      padding-left: 0;
      -webkit-appearance: none;
      appearance: none;
      width: 16px;
      height: 16px;
      border-radius: 3px;
      //background: $darkThemeBaseDarkDarkest;
      box-shadow: 0px 1px 2px 0px rgba(255, 255, 255, 0.1),
      0px 0px 0px 1px #2e3035,
      0px 0.5px 0px 1.5px rgba(0, 0, 0, 0.4);
      border: 1px solid $darkThemeBaseDarkDarker;
      background: $darkThemeBaseDarkDarker;
      transition: 0.5s all;

      &:hover {
        background: $darkThemeBaseDarkLight;
        border-color: $darkThemeBaseDarkLight;
      }

      &.Checked {
        border-radius: 3px;
        border-color: $darkThemeBasePurple;
        background: $darkThemeBasePurple;
        box-shadow: 0 0 0 1px $darkThemeBasePurple;
      }

      &.CheckboxBorder {
        border: 1px solid $darkThemeBasePurple;
      }

      &.CheckboxError {
        border: 1px solid $darkThemeBaseRed !important;
        box-shadow: 0 0 0 3px rgba(225, 29, 72, 0.25);
      }

      &:disabled {
        border-color: $darkThemeBaseDarkDarkest;
        background: $darkThemeBaseDarkDarkest;
        box-shadow: 0px 0px 0px 1px $darkThemeBaseDarkDarkest;
      }
    }

    .CheckedIcon {
      position: absolute;
      margin: auto;
      //top: -6px;
      left: -3px;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .LabeledCheckedIcon {
      //top: -3px;
    }

    .ParentCheckedIcon {
      left: -2px;
    }
  }

  .Bordered {
    align-items: unset;

    .Checkbox {
      margin-top: 4px;
    }

    .CheckedIcon {
      top: -2px;
      height: auto;
      display: block;
    }
  }

  &.Border {
    border-radius: $primary-border-radius;
    padding: $checkbox-border-padding;
  }

  span {
    padding-top: 0;
    padding-left: 0;
  }

  .CheckboxText {
    font-size: 0.9rem;

    .CheckboxLabel {
      font-weight: 600;
    }

    .CheckboxDescription {
      line-height: 1.4rem;
    }
  }

  .Error {
    color: $darkThemeBaseRed;
    font-size: 12px;
  }
}

[data-theme='light'] {
}

[data-theme='dark'] {
  .CheckboxContainer {
    &.Border {
      background: $darkThemeBaseDarkDarkest;
      border: 1px solid $darkThemeBaseDarkDarker;
      box-shadow: $checkbox-border-box-shadow;

      &.Checked {
        border: 1px solid $darkThemeBasePurple;
      }
    }

    &.Disabled {
      .CheckedIcon {
        svg {
          color: $darkThemeBaseDarkLight;
        }
      }

      &.Border {
        cursor: not-allowed;
        border: 1px solid $darkThemeBaseDarkDarker;
        background: $darkThemeBaseDarkDarkest;

        .CheckboxWrapper {
          cursor: not-allowed;

          input,
          label {
            cursor: not-allowed;
          }

          .Checkbox {
            background: $darkThemeBaseDarkDarkest;
            border: 1px solid $darkThemeBaseDarkDarker;
            box-shadow: none;
          }
        }
      }

      .CheckboxText {
        .CheckboxLabel {
          color: $darkThemeBaseDarkLight;
        }

        .CheckboxDescription {
          color: $darkThemeBaseDarkLight;
        }
      }
    }

    .CheckboxText {
      .CheckboxLabel {
        color: $darkThemeBaseWhiteLightest;
      }

      .CheckboxDescription {
        color: $darkThemeTagNeutralIcon;
      }
    }
  }
}
