@use '@glise/styles/globals' as *;
button.IconButton {
  border-radius: $secondary-border-radius;
  height: 40px;
  width: 40px;
  transition: 0.5s all;
  position: relative;

  > img {
    border-radius: 0.375rem;
    width: 100%;
    height: 100%;
  }

  &.transparent {
    outline: none;
  }

  &.sm {
    width: 30px;
    height: 30px;
    padding: 0;
  }

  &.md {
    padding: 0;
    //padding: $icon-button-padding-md;
  }

  &.lg {
    height: 30px;
    width: 30px;
    padding: 0;
    //padding: $icon-button-padding-lg;
  }

  &.xl {
    padding: 0;
    //padding: $icon-button-padding-xl;
  }

  &.Disabled {
    opacity: 0.7;
    cursor: not-allowed !important;
  }
}

[data-theme='light'] {
  button.IconButton {
    border: 1px solid $lightThemeBaseWhite;
    background: $lightThemeBaseWhiteLight;
    color: black;

    svg {
      color: $lightThemeTagNeutralIcon;
    }

    &.transparent {
      border: 1px solid transparent;
      outline: none;
      background: transparent;

      &:hover {
        border: 1px solid transparent;
        background: transparent;
      }

      &:active {
        border: 1px solid transparent;
        background: transparent;
      }
    }

    &:hover {
      background-color: $lightThemeBaseWhiteLightest;
    }

    &:active {
      background-color: $lightThemeBaseWhiteLightest;
    }
  }
}

[data-theme='dark'] {
  button.IconButton {
    border: 1px solid $darkThemeBaseDarkDarker;
    background: $darkThemeBaseDarkDarkest;
    color: $darkThemeBaseWhiteLightest;

    &.transparent-active {
      background: none;
      border: none;
    }

    &.transparent {
      border: 1px solid transparent;
      outline: none;
      background: transparent;

      svg {
        color: $darkThemeTagNeutralIcon;
      }

      &:hover {
        border: 1px solid transparent;
        background: transparent;
      }

      &:active {
        border: 1px solid transparent;
        background: transparent;
      }
    }

    &.white {
      svg {
        color: $darkThemeBaseWhite;
      }
    }

    &:hover {
      background-color: $darkThemeBaseDark;
      border-color: $darkThemeBaseDark;
    }

    &:active {
      background-color: $darkThemeBaseDark;
      border-color: $darkThemeBaseDark;
    }
  }
}
