@use '@glise/styles/globals' as *;
div.CookiesModal {
  border-radius: 16px;
  background-color: #0d0d0f;
  padding: 3rem;
  color: $darkThemeBaseWhiteDarker;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @include md {
    padding: 1.5rem;
  }

  .Title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $darkThemeBaseWhiteDarker;
    font-size: 24px;
  }

  .AgreeText,
  a {
    color: $lightThemeBaseGrayLight;
    font-size: 15px;
    font-weight: 300;

    a {
      transition: 0.25s ease-in-out;
      color: $darkThemeBasePurple;
    }
  }

  .CheckboxesContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .ActionButtonsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
  }
}
