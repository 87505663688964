@use 'fonts';
@use 'breakpoints';

// General
$primary-border-radius: 0.5rem;
$secondary-border-radius: 0.375rem;
$small-border-radius: 1rem;
$primary-box-shadow: 0 0 0 1px #e5e7eb,
0 1px 2px 0 rgba(3, 7, 18, 0.2);
$error-box-shadow: 0 0 0 0.1875rem rgba(225, 29, 72, 0.15);
$font-smaller: 0.8125rem;
$primary-gap: 0.625rem;
$gray-border-solid: 0.0625rem solid #e5e7eb;
$navbarClosedWidth: 4.6rem;
$navbarOpenedWidth: 15rem;

// Button
$button-padding-sm: 0.35rem 0.65rem;
$button-padding-md: 0.35rem 0.5rem;
$button-padding-lg: 0.6rem 0.8rem;
$button-padding-xl: 0.8rem 1rem;
$button-fontsize-md: 0.8rem;
$button-fontsize-lg: 0.9rem;
$button-fontsize-xl: 1rem;
$button-box-shadow-focus: 0 0 0 3px rgba(107, 104, 255, 0.6),
0 0 0 1px #ffffff;

// Icon Button
$icon-button-padding-sm: 0.35rem;
$icon-button-padding-md: 0.35rem;
$icon-button-padding-lg: 0rem;
$icon-button-padding-xl: 0.85rem;

// Checkbox
$checkbox-border-padding: 0.75rem;
$checkbox-border-radius: 0.1875rem;
$checkbox-border-box-shadow: 0 0.25rem 0.5rem 0 rgba(3, 7, 18, 0.04),
0 0.125rem 0.25rem -0.0625rem rgba(3, 7, 18, 0.08),
0 0 0 0.0625rem rgba(3, 7, 18, 0.08);
$checkbox-border-box-shadow-checked: 0 0 0 1px #6b68ff,
0 1px 2px 0 rgba(30, 58, 138, 0.5);
$checkbox-border-box-shadow-focused: 0 0 0 4px rgba(107, 104, 255, 0.6),
0 0 0 2px white,
0 0 0 1px #3b82f6,
0 1px 2px 0 rgba(30, 58, 138, 0.5);
$checkbox-border-box-shadow-hover: 0 0.5rem 2rem 0 rgba(3, 7, 18, 0.1),
0 0.25rem 0.5rem -0.125rem rgba(3, 7, 18, 0.08),
0 0 0 0.125rem rgba(3, 7, 18, 0.08);
$checkbox-icon-box-shadow: 0px 0px 0px 1px #e5e7eb,
0px 1px 2px 0px rgba(3, 7, 18, 0.2);

// FormInput
$input-padding-md: 0.5rem 0.75rem;
$input-padding-sm: 0.25rem 0.5rem;
$input-padding-textarea: 0.4375rem 0.75rem 0.5rem 0.75rem;
$input-box-shadow: 0 0.03125rem 0 0 rgba(3, 7, 18, 0.01),
0 0.015625rem 0 0 rgba(3, 7, 18, 0.01);
$input-box-shadow-hover: 0 0.03125rem 0 0 rgba(3, 7, 18, 0.01),
0 0.015625rem 0 0 rgba(3, 7, 18, 0.01);

// Pill
$pill-button-padding: 0.375rem 0.75rem;
$pill-button-padding: 0.375rem 0.75rem;

// RadioButton
$radiobutton-border-padding: 0.75rem;

// Card
$card-padding: 0.75rem 1rem;
$card-box-shadow: 0 2px 4px 0 rgba(3, 7, 18, 0.04),
0 1px 2px -1px rgba(3, 7, 18, 0.08),
0 0 0 1px rgba(3, 7, 18, 0.08);
$card-box-shadow-hover: 0 4px 8px 0 rgba(3, 7, 18, 0.08),
0 0 0 1px rgba(3, 7, 18, 0.08);
$card-border-radius: 6.25rem;
$card-avatar-padding: 0.25rem;

// Avatar
$avatar-border-radius: 62.4375rem;
$avatar-squared-border-radius: 0.25rem;

// Chip
$chip-padding-sm: 0 0.25rem;
$chip-padding-md: 0.0625rem 0.375rem;
$chip-padding-xl: 0.1875rem 0.5rem;
$chip-border-radius-round: 625rem;

// DatePicker
$date-box-shadow: 0 0.03125rem 0 0 rgba(3, 7, 18, 0.16),
0 0.015625rem 0 0 rgba(3, 7, 18, 0.16);
$date-box-shadow-hover: 0 0.03125rem 0 0 rgba(3, 7, 18, 0.16),
0 0.015625rem 0 0 rgba(3, 7, 18, 0.16);
$date-padding-sm: 0 0.625rem;
$date-padding-md: 0.1875rem 0.625rem;

// Tooltip
$tooltip-box-shadow: 0 4px 8px 0 rgba(3, 7, 18, 0.08),
0 0 0 1px rgba(3, 7, 18, 0.08);
$tooltip-padding: 0.5rem 1rem;

// Toast
$toast-box-shadow: 0 0.5rem 1rem 0 rgba(3, 7, 18, 0.08),
0 0 0 0.0625rem rgba(3, 7, 18, 0.08);

// Switch
$switch-box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.02) inset,
0 0 0 0.03125rem rgba(0, 0, 0, 0.06) inset,
0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.04) inset,
0 0.03125rem 0.03125rem 0 rgba(0, 0, 0, 0.04) inset;
$switch-box-shadow-focused: 0 0 0.5rem 0 rgba(0, 0, 0, 0.02) inset,
0 0 0 0.03125rem rgba(0, 0, 0, 0.06) inset,
0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.04) inset,
0 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0.04) inset;
$switch-slider-box-shadow: 0 0 4px 0 rgba(3, 7, 18, 0.02),
0.5px 0.5px 0.5px 0.5px rgba(3, 7, 18, 0.06);

@mixin colorfulTextBig {
  font-size: 5rem;
  line-height: 5rem;
  letter-spacing: -0.2rem;
  text-transform: capitalize;
  background-image: linear-gradient(148deg, #fff 19.13%, #5c44f2 125.28%);
  color: transparent;
  background-clip: text;

  @include breakpoints.md {
    font-size: 3rem;
    line-height: 3.75rem;
  }
}

@mixin colorfulTextSmall {
  font-size: 1.25rem;
  font-weight: 300;

  @include breakpoints.md {
    padding-top: 0;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
