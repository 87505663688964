@use '@glise/styles/globals' as *;
.MobileHeaderContainer {
  display: flex;
  flex-direction: column;
  z-index: 20;
  width: 100%;
  border-bottom: 0.5px solid $darkThemeBaseDark;
  top: 0;
  left: 0;
  right: 0;

  position: fixed;

  .MobileHeaderWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 0.5px solid $darkThemeBaseDark;
    background: rgba(3, 3, 3, 0.24);
    backdrop-filter: blur(120px);
    -webkit-backdrop-filter: blur(120px);
    will-change: backdrop-filter;
    z-index: 21;
    transition: 0.3s ease-in-out;

    &.OpenMenu {
      background: #0d0d0f;
      backdrop-filter: blur(0px);
      -webkit-backdrop-filter: blur(0px);
      will-change: unset;
    }

    .ButtonsWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      .SandwichButton {
        width: 1rem;
        height: 1rem;
        border: 1px solid rgba(237, 238, 240, 0.08);
        border-radius: 0.45rem;
        padding: 1rem;
        position: relative;

        svg {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          color: $darkThemeBaseWhiteLightest;
        }
      }
    }
  }

  .ModalContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #0d0d0f;
    gap: 1rem;
    text-align: center;
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: -0.02rem;
    padding-top: 1rem;
    position: fixed;
    width: 100%;
    top: 66px;
    bottom: 0;
    z-index: 20;
    height: fit-content;
    transform: translateY(-200%);
    transition: 0.3s ease-in-out;

    &.Open {
      transform: translateY(0%);
    }

    .NavLink {
      width: 100%;
      color: $darkThemeBaseWhiteLightest;
      font-size: 18px;
      padding: 0 1rem;
    }

    .ActionsWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      padding: 2rem 1rem;
      background-color: rgba(107, 104, 255, 0.16);

      .ActionLink {
        width: 100%;

        .TrialButton {
          width: 100%;
        }
      }

      .LoginButton {
        width: 100%;
        border: 1px solid $darkThemeBaseDarkDarker !important;
        padding: 1.5rem 1rem;
      }
    }
  }
}
