@use '@glise/styles/globals' as *;
.Input {
  border-radius: 6px;
  transition: 0.5s all;
  outline: unset;

  &.md {
    padding: $input-padding-md;
    font-size: 14px;
    height: 40px;
  }

  &.sm {
    padding: $input-padding-sm;
    font-size: 13px;
  }

  &.FullWidth {
    width: 100%;
  }

  &::placeholder {
    font-size: 0.875rem;
  }
}

[data-theme='light'] {
  .Input {
    border: 1px solid $darkThemeBaseDarkDarker;
    background: $darkThemeBaseDarkDarkest;

    &::placeholder {
      color: $darkThemeBaseDarkLight;
    }

    &:hover,
    &:active {
      border: 1px solid $darkThemeBaseDarkDarker;
      background: $darkThemeBaseDarkDarker;
      box-shadow: 0 0.5px 0 0 rgba(0, 0, 0, 0.6),
      0 0.25px 0 0 rgba(0, 0, 0, 0.6),
      0 1.5px 0 0 rgba(255, 255, 255, 0.1) inset;
    }

    &:focus {
    }

    &:disabled {
      cursor: not-allowed;

      &::placeholder {
        color: $darkThemeBaseDarkLight;
      }
    }

    &.InputError {
      border: 1px solid $darkThemeBaseRed !important;
      background: $darkThemeBaseDarkDarkest;
      box-shadow: 0 0 0 3px rgba(225, 29, 72, 0.25);
    }
  }
}

[data-theme='dark'] {
  .Input {
    border: 1px solid $darkThemeBaseDarkDarker;
    background: $darkThemeBaseDarkDarkest;
    color: $darkThemeBaseWhite;

    &::placeholder {
      color: $darkThemeBaseGray;
    }

    &:hover,
    &:active,
    &:focus {
      border: 1px solid $darkThemeBaseDarkDarker;
      background: $darkThemeBaseDarkDarker;
    }

    &:focus {
      border: 1px solid $darkThemeBasePurple;
    }

    &:disabled {
      cursor: not-allowed;
      border: 1px solid $darkThemeBaseDarkDarker;
      background: $darkThemeBaseDarkDarkest;

      &::placeholder {
        color: $darkThemeBaseDarkLight;
      }
    }

    &.InputError {
      border: 1px solid $darkThemeBaseRed !important;
      background: $darkThemeBaseDarkDarkest;
      box-shadow: 0 0 0 3px rgba(225, 29, 72, 0.25);
    }
  }
}

.Input[type='search']::-webkit-search-decoration,
.Input[type='search']::-webkit-search-cancel-button,
.Input[type='search']::-webkit-search-results-button,
.Input[type='search']::-webkit-search-results-decoration {
  display: none;
}
