@use '@glise/styles/globals' as *;
@use 'breakpoints';
@use 'colors';
@use 'fonts';
@use 'variables';

html,
body {
  //overflow-x: hidden !important;
  color: colors.$darkThemeBaseWhiteLightest !important;

  img {
    -webkit-user-drag: none;
    -webkit-user-select: none;
    user-drag: none;
    user-select: none;
  }

  //> *, span, li, ul, input, textarea, div {
  //  font-family: var(--font-primary) !important;
  //}

  #__next {
    position: relative;
  }

  .slider-container {
    width: 100%;

    .slider-list {
      gap: 1.5rem;
    }
  }

  .hot-toast {
    > * {
      > * {
        padding: 0;
        background: none;
        max-width: fit-content;

        > * {
          margin: 0;
        }
      }
    }
  }
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none !important;
}

.grecaptcha-badge {
  display: none !important;
}

a {
  text-decoration: none !important;
}

.rdp {
  width: min-content;
  border-radius: 16px;
  margin: 0;

  .rdp-caption_label {
    font-weight: 800;
  }

  .rdp-head_cell {
    font-weight: 100;
    font-size: 12px;
  }

  .rdp-nav {
    display: flex;
    gap: 0.25rem;

    .rdp-nav_button {
      border-radius: 0.375rem;
    }
  }
}

[data-theme='dark'] {
  body {
    background: #0d0d0f;
  }

  .rdp-day_selected {
    background-color: colors.$darkThemeBasePurple;
  }

  .rdp-nav_button {
    border: 1px solid colors.$darkThemeBaseDarkDarker;
    background: colors.$darkThemeBaseDarkDarkest;
    color: colors.$darkThemeBaseWhiteDarkest;
  }

  .rdp {
    .rdp-caption_label {
      color: colors.$darkThemeBaseWhiteDarker;
    }

    .rdp-head_cell {
      color: colors.$darkThemeBaseWhiteDarker;
    }

    .rdp-day {
      color: colors.$darkThemeBaseWhiteDarker;
    }

    .rdp-day:hover {
      background-color: colors.$darkThemeBasePurple !important;
    }

    .rdp-day_outside {
      color: colors.$darkThemeBaseGray;
    }
  }
}
