@use '@glise/styles/globals' as *;
.Header {
  border-bottom: 0.5px solid $darkThemeBaseDark;
  background: rgba(3, 3, 3, 0.24);
  backdrop-filter: blur(12px);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;

  .HeaderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
  }

  .HeaderLinksContainer {
    display: flex;
    align-items: center;
    gap: 1.5rem;

    .Logo {
      margin-right: 2.5rem;
      transition: 0.5s all;

      @include lg {
        margin: 0;
      }

      svg {
        path {
          transition: 0.25s all;
        }
      }

      &:hover {
        svg {
          path {
            fill: $darkThemeBasePurple;
          }
        }
      }
    }

    a {
      color: $darkThemeBaseWhiteLightest;
      text-align: center;

      font-size: 0.875rem;
      line-height: 1.25rem;
      white-space: nowrap;

      &:hover {
        color: $darkThemeBasePurple;
      }
    }
  }

  .HeaderButtonsContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    white-space: nowrap;

    @include lg {
      margin-left: 1rem;
    }
  }
}

.Active {
  background: red;
}
