@use '@glise/styles/globals' as *;
.FormInput {
  .Label {
    color: $darkThemeBaseWhiteDarker;

    font-size: 12px;
    font-weight: 400;
    line-height: 1.25rem;
    padding-bottom: 0.25rem;
  }

  .Error {
    color: $darkThemeBaseRed;
    font-size: 12px;
  }

  .Required {
    font-size: 14px;
    color: $darkThemeBaseRedLight;
  }

  .FilterLabel {
    color: $darkThemeBaseWhiteDarker;
    font-size: 12px;
    padding-bottom: 0.5rem;
  }

  .InputWrapper {
    position: relative;
    color: $darkThemeBaseWhite;
    height: 100%;

    .LeftIcon {
      position: absolute;
      left: 1rem;
      bottom: 0;
      top: 0;
      margin: auto;
      height: fit-content;
      display: flex;
      align-items: center;
      //color: $darkThemeBaseGray;
    }

    .StartCustomComponent {
      left: 0.35rem;
    }

    .LeftSearch {
      color: $darkThemeBaseGray;
      //top: -5px;
      pointer-events: none;
      left: 0.5rem;
    }

    .RightIcon {
      height: 20px;
      width: 20px;
      right: 8px;
      top: 50%;
      margin-top: -10px;
      position: absolute;
      pointer-events: none;

      &.PointerEvents {
        pointer-events: all;
      }

      &.md {
        margin-top: -10px;
      }

      .CloseLeftBorder {
        left: 0.25rem;
      }

      .RightIconWrapper {
        position: relative;
        width: 100%;
        height: 100%;
        cursor: pointer;

        svg {
          position: absolute;
        }

        svg {
          path {
            transition: 0.25s all;
          }
        }

        &:hover {
          svg {
            color: $darkThemeBaseWhite;
          }
        }
      }
    }

    .Search {
      padding-left: 2rem;
      //padding-right: 2rem;
    }

    .Password {
      padding-right: 2rem;
    }

    .Clickable {
      cursor: pointer;
      pointer-events: visible;
    }
  }
}

[data-theme='light'] {
}
